.section {
    &__work &__heading {
        font-size: 26px;
        color: var(--secondary);
        margin-top: 60px;
        margin-bottom: 40px;
        text-align: center;
    }
    &__work &__cards {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        justify-content: center;
        margin-bottom: 60px;

        @media (max-width: 660px) {
            gap: 16px;
        }
        @media (max-width: 530px) {
            gap: 4px;
        }
    }

    &__work &__card {
        width: 218px;

        @media (max-width: 530px) {
            padding: 5px;
            width: 190px;
        }
        @media (max-width: 416px) {
            width: 174px;
        }
        @media (max-width: 386px) {
            width: 100%;
        }


        .card__header {
            height: 56px;
            align-items: center;
        }
    }

    &__work {
        .card__group {
            display: flex;
            gap: 30px;
            
            @media (max-width: 660px) {
                gap: 16px;
            }
            @media (max-width: 530px) {
                gap: 4px;
            }
            @media (max-width: 386px) {
                flex-wrap: wrap;
            }
        }
    }
}