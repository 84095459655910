.nav {
    &__list {
        display: flex;
        list-style-type: none;
    }
    &__item {
        text-transform: uppercase;
        padding: 0 30px;
        font-size: 12px;
        font-weight: 700;

        &:first-of-type {
            padding-left: 0;
        }

        a {
            position: relative;
            color: var(--white);
            text-decoration: none;

            &:hover {
                color: var(--primary);
            }
        }

        &--is_active {
            a {
                color: var(--primary);

                &:after {
                    position: absolute;
                    content: "";
                    height: 2px;
                    bottom: -12px;

                    margin: 0 auto;
                    left: 0;
                    right: 0;
                    width: 24px;
                    background: var(--primary);
                }
            }
        }
    }

    @media (max-width: 960px) {
        display: none;
    }
}

.hamburger {
    @media (min-width: 960px) {
        display: none;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        padding: 15px;
        padding-top: 20px;
    }

    &__list {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 0;
    }

    &__item {
        padding: 20px!important;
        font-size: 24px;
    }

    &__icon {
        cursor: pointer;
        position: relative;
        top: 12px;

        &--no_padding {
            top: 0;
        }
    }

    &__wrapper {
        position: fixed;

        top: 0;
        left: 0;

        width: 100vw;
        height: 100vh;

        background: radial-gradient(197.67% 93.62% at 50% 6.38%, var(--bg-primary) 0%, var(--bg-primary-dark) 100%);

        z-index: 2;
    }
}