.section {
    &__services {
        background: radial-gradient(83.25% 179.08% at 50% 8.4%, var(--bg-primary) 0%,var(--bg-primary-dark) 100%);
    }
    &__services &__heading {
        color: var(--primary);
        font-size: 26px;
        margin: 40px 0;
    }

    &__services &__cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 30px;

        @media (max-width: 660px) {
            gap: 20px;
        }
    }

    &__services &__button {
        margin-top: 40px;
        margin-bottom: 60px;

        @media (max-width: 660px) {
            width: -webkit-fill-available;
            text-align: center;
        }
    }

    &__services &__card {
        width: 316px;

        @media (max-width: 660px) {
            width: 100%;
        }
    }
}