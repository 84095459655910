.section {
    &__mission {
        background: radial-gradient(83.25% 179.08% at 50% 8.4%, var(--bg-primary) 0%,var(--bg-primary-dark) 100%);
    }

    &__mission &__heading {
        color: var(--primary);
        font-size: 26px;
        margin-top: 40px;
        margin-bottom: 45px;
    }

    &__our {
        display: flex;
        color: var(--white);
        gap: 14px;
        margin-bottom: 55px;

        .our_block {
            margin-top: 10px;

            &__title {
                font-size: 20px;
                margin-bottom: 20px;
            }
            &__text {
                font-size: 20px;
            }
            &__separator {
                background: var(--white);
                opacity: 0.3;
                width: 1px;
                height: 130px;
            }
        }
    }

    &__mission &__form {
        max-width: 730px;
        margin-bottom: 40px;
    }
}

.mission__container {
    max-width: 730px;
    text-align: center;
}