.form {
    background: white;
    padding: 25px;
    border-radius: 10px;

    &__title {
        margin-top: 15px;
        font-size: 26px;
    }

    &__subtitle {
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;

        margin-bottom: 30px;
    }

    &__inputs {
        max-width: 540px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    &__button {
        display: block;
        width: 255px;
        margin: 0 auto;
        margin-top: 20px;

        @media (max-width: 660px) {
            width: -webkit-fill-available;
        }
    }

    &__input {
        div {
            border-radius: 6px!important; 
        }

        label {
            color:#696969!important;
        }

        &--mul {
            &>div:not(:focus) {
                min-height: 90px;
            }
        }
    }
}