.home {
    &__container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        @media (max-width: 1200px) {
            width: 100%;
        }
    }

    &__background {
        position: absolute;
        top: 0;
        left: 0;
    }

    &__heading {
        font-size: 32px;
        line-height: 38px;
        letter-spacing: 0em;
        padding-top: 67px;
        color: var(--primary);
    }

    &__item {
        width: 50%;

        @media (max-width: 1200px) {
            width: 100%;
            text-align: center;
        }
    }

    &__text {
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        padding-bottom: 40px;
        color: var(--white);

        @media (max-width: 600px) {
            padding-bottom: 12px;
        } 
    }

    &__illustration {
        padding-top: 70px;
        position: relative;
        left: 10px;

        display: block;
        margin: 0 auto;

        @media (max-width: 600px) {
            width: 100%;
        }
    }

    &__button {
        @media (max-width: 660px) {
            display: block;
        } 
    }
}
