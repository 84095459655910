.footer {
    display: flex;
    justify-content: center;

    &__text {
        position: relative;
        top: 37px;
        z-index: 99;

        width: 100%;
        max-width: 580px;
        padding: 10px;
        line-height: 14px;
        text-align: center;
        background: var(--white);
        color: var(--text-secondary);
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
    }

    &__info {
        margin-top: 5px;
        margin-bottom: 5px;

        .info__item {
            margin: 4px 0;;
            display: inline-block;

            @media (max-width: 450px) {
                display: block;
            }
        }
    }

    &__separator {
        display: inline-block;
        width: 1px;
        height: 14px;
        background: gray;
        margin-left: 15px;
        margin-right: 15px;

        @media (max-width: 450px) {
            display: none;
        }
    }
}