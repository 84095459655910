:root {
    --white: white;
    --primary: #FFDA00;
    --secondary: #3A1DCC;

    --bg-primary: #1C0560;
    --bg-primary-dark: #15043A;

    --text-secondary: #3D3D3D;
}

.title_bold {
    font-weight: 900;
    text-transform: uppercase;
}

.section {
    padding-left: 15px;
    padding-right: 15px;
}