.card {
    border: 1px solid #EDECF9;
    border-radius: 10px;
    padding: 16px;
    background: var(--white);

    &__logo {
        max-width: 42px;
        max-height: 42px;
    }

    &__header { 
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;

        .card--center & {
            flex-direction: column;
        }
    }

    &__text {
        text-align: left;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 17px;
        letter-spacing: 0em;

        .card--center & {
            text-align: center;
        }
    }

    &__title {
        font-size: 16px;
        padding-left: 10px;
        margin-top: 0;
        margin-bottom: 0;
        

        .card--center & {
            padding-left: 0;
            margin-bottom: 14px;
        }
    }

    &--center {
        text-align: center;
    }
}