header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .logo {
        &__container {
            display: flex;
            align-items: center;
        }

        &__title {
            font-size: 30px;
            font-weight: 900;
            line-height: 36px;

            color: var(--white);

            margin-left: 12px;
        }
    }
}