.section {
    &__about &__heading {
        margin-top: 60px;
        margin-bottom: 30px;
        color: var(--secondary);
    }

    &__about &__card {
        width: 255px;

        @media (max-width: 660px) {
            width: 100%;
        }
    }

    .block__heading {
        font-size: 20px;
    }
    &__text {
        font-size: 15px;
        font-style: normal;
        font-weight: 300;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: center;
    }

    .about__block {
        display: flex;
        gap: 30px;
        justify-content: center;
        flex-wrap: wrap;
        padding-bottom: 60px;

        @media (max-width: 660px) {
            gap: 20px;
        }
    }
}